exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-home-cart-tsx": () => import("./../../../src/pages/home/cart.tsx" /* webpackChunkName: "component---src-pages-home-cart-tsx" */),
  "component---src-pages-home-checkout-tsx": () => import("./../../../src/pages/home/checkout.tsx" /* webpackChunkName: "component---src-pages-home-checkout-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-home-products-[id]-tsx": () => import("./../../../src/pages/home/products/[id].tsx" /* webpackChunkName: "component---src-pages-home-products-[id]-tsx" */),
  "component---src-pages-home-products-index-tsx": () => import("./../../../src/pages/home/products/index.tsx" /* webpackChunkName: "component---src-pages-home-products-index-tsx" */),
  "component---src-pages-home-profile-addresses-tsx": () => import("./../../../src/pages/home/profile/addresses.tsx" /* webpackChunkName: "component---src-pages-home-profile-addresses-tsx" */),
  "component---src-pages-home-profile-bid-tsx": () => import("./../../../src/pages/home/profile/bid.tsx" /* webpackChunkName: "component---src-pages-home-profile-bid-tsx" */),
  "component---src-pages-home-profile-catalogue-tsx": () => import("./../../../src/pages/home/profile/catalogue.tsx" /* webpackChunkName: "component---src-pages-home-profile-catalogue-tsx" */),
  "component---src-pages-home-profile-details-tsx": () => import("./../../../src/pages/home/profile/details.tsx" /* webpackChunkName: "component---src-pages-home-profile-details-tsx" */),
  "component---src-pages-home-profile-faq-page-tsx": () => import("./../../../src/pages/home/profile/faqPage.tsx" /* webpackChunkName: "component---src-pages-home-profile-faq-page-tsx" */),
  "component---src-pages-home-profile-index-tsx": () => import("./../../../src/pages/home/profile/index.tsx" /* webpackChunkName: "component---src-pages-home-profile-index-tsx" */),
  "component---src-pages-home-profile-legal-tsx": () => import("./../../../src/pages/home/profile/legal.tsx" /* webpackChunkName: "component---src-pages-home-profile-legal-tsx" */),
  "component---src-pages-home-profile-orders-[id]-tsx": () => import("./../../../src/pages/home/profile/orders/[id].tsx" /* webpackChunkName: "component---src-pages-home-profile-orders-[id]-tsx" */),
  "component---src-pages-home-profile-orders-index-tsx": () => import("./../../../src/pages/home/profile/orders/index.tsx" /* webpackChunkName: "component---src-pages-home-profile-orders-index-tsx" */),
  "component---src-pages-home-profile-org-budget-tsx": () => import("./../../../src/pages/home/profile/org/budget.tsx" /* webpackChunkName: "component---src-pages-home-profile-org-budget-tsx" */),
  "component---src-pages-home-profile-org-cost-centers-tsx": () => import("./../../../src/pages/home/profile/org/cost_centers.tsx" /* webpackChunkName: "component---src-pages-home-profile-org-cost-centers-tsx" */),
  "component---src-pages-home-profile-org-index-tsx": () => import("./../../../src/pages/home/profile/org/index.tsx" /* webpackChunkName: "component---src-pages-home-profile-org-index-tsx" */),
  "component---src-pages-home-profile-org-team-members-tsx": () => import("./../../../src/pages/home/profile/org/team_members.tsx" /* webpackChunkName: "component---src-pages-home-profile-org-team-members-tsx" */),
  "component---src-pages-home-profile-request-product-tsx": () => import("./../../../src/pages/home/profile/requestProduct.tsx" /* webpackChunkName: "component---src-pages-home-profile-request-product-tsx" */),
  "component---src-pages-home-profile-support-tsx": () => import("./../../../src/pages/home/profile/support.tsx" /* webpackChunkName: "component---src-pages-home-profile-support-tsx" */),
  "component---src-pages-home-profile-wallet-tsx": () => import("./../../../src/pages/home/profile/wallet.tsx" /* webpackChunkName: "component---src-pages-home-profile-wallet-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-payment-index-tsx": () => import("./../../../src/pages/payment/index.tsx" /* webpackChunkName: "component---src-pages-payment-index-tsx" */),
  "component---src-pages-payment-verify-[id]-tsx": () => import("./../../../src/pages/payment/verify/[id].tsx" /* webpackChunkName: "component---src-pages-payment-verify-[id]-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-refund-tsx": () => import("./../../../src/pages/refund.tsx" /* webpackChunkName: "component---src-pages-refund-tsx" */),
  "component---src-pages-returns-tsx": () => import("./../../../src/pages/returns.tsx" /* webpackChunkName: "component---src-pages-returns-tsx" */),
  "component---src-pages-shipping-tsx": () => import("./../../../src/pages/shipping.tsx" /* webpackChunkName: "component---src-pages-shipping-tsx" */)
}

